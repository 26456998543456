import './index.css';
import React, {useEffect} from "react";
import ScrollTrigger from "gsap/ScrollTrigger"
import gsap from "gsap/gsap-core";

function Page4() {

    useEffect(()=> {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
            trigger: ".page4-m",
            toggleActions: "play none play reset",
            pin: ".page4-m",
            pinSpacing: false,
            start: "top top",
            end: "bottom top"
        });
    }, []);

    return (
        <div id="page4-m" className="page4-m">
            <div className="wrapper">
                <div className="content">
                    <div className="material"/>
                    <div className="driven"/>
                    <div className="design"/>
                    <div className="desc">
                        소재―중심―디자인,<br/>
                        소재를 우선으로 연구하고 이를 바탕으로 실생활의 <br/>
                        필요를 찾아 제품을 개발하는 방법론입니다. <br/>
                        이러한 방법론은 선행 연구 분야로서, 다양한 산업 영역에서<br/>
                        미래를 이끌어오고 있습니다.
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Page4;
