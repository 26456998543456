import './index.css';
import React from "react";
import Project from "./Project";
import maskcase from "./maskcase@3x.png" ;
import photocatalysisInterior from "./photocatalyst-interior@3x.png";

function Page7_Project() {


    return (
        <div className="page7-prj-m" id="page7-prj-m">
            <div className="wrapper">
                <div className="section">
                    <div className="section-title">진행 중인 프로젝트</div>
                    <div className="section-subtitle">Current Projects</div>

                    <div className="project-wrapper">
                        <Project
                            title={"마스크 살균 케이스"}
                            subtitle={"UV Face Mask Sterilizer"}
                            content={"Zero V 마스크 케이스는 UVC-LED를 활용한 마스크 살균 기능의 휴대용 케이스입니다. \n" +
                            "외관 소재는 바이오 플라스틱을 적용하여 인체에 안전한 플라스틱 소재를 적용하되, 플라스틱 사용량을 절감할 수 있도록 하였습니다."}
                            imagePath={maskcase}
                        />
                        <Project
                            title={"광촉매 실내 마감재"}
                            subtitle={"Photocatalysis Interior Material"}
                            content={"M.0의 부설연구소에서 연구 개발 중인 광촉매 소재는, 2019년 첫번째 프로토타입이 가구에 접목되어 소개되면서 해외 매거진 Wallpaper에도 실리게 되었습니다. \n" +
                            "M.0가 진행중인 실내 환경 개선 프로젝트의 하나로서 기존의 광촉매 소재보다 더욱 효과적으로 실내 공기질을 개선시킬 수 있는 실내마감재를 개발 중에 있으며, 첫번째 제품은 2021년 초에 선보일 예정입니다."}
                            imagePath={photocatalysisInterior}
                        />

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page7_Project;
