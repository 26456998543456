import './App.css';
import React from "react";
import Descktop from "./Desktop"
import Mobile from "./Mobile"


function App() {

    const isMobile = window.innerWidth < 1190;
    return (
        <div className="App" id="app">
            {
                isMobile && <Mobile/>
            }
            {
                !isMobile && <Descktop/>
            }

            <div id="toast">클립보드에 복사되었습니다.</div>
        </div>
    );
}

export default App;
