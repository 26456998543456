import './index.css';
import React, {useEffect} from "react";
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

function Page2() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.create({
            trigger: ".page2",
            onToggle: ({progress, direction, isActive}) => {
                if (isActive || direction > 0) {
                    document.getElementsByClassName("topbar")[0].classList.add("dark")
                } else {
                    document.getElementsByClassName("topbar")[0].classList.remove("dark")
                }
            },
            start: "top top"
        });

        ScrollTrigger.create({
            trigger: ".page2",
            toggleActions: "play none play reset",
            pin: ".page2 .wrapper",
            pinSpacing: false,
            start: "top top",
            end: "bottom top"
        });

        gsap.from(".page2 .content", {
            opacity: 0,
            duration: 0.2,
            y: -100,
            scrollTrigger: {
                trigger: ".page2",
                toggleActions: "play complete none none",
                start: "top top+=100"
            }
        });
    }, []);


    return (
        <div id="page2" className="page2 panel">
            <div className="wrapper">
            <div className="content">
                <div className="text">
                    엠.제로<span>(M.0)</span>는 소재와 디자인을 통해<br/>
                    미래를 제시합니다.
                </div>
                <div className="desc">
                    We shape the future with materials and design.
                </div>
            </div>
            </div>
        </div>
    );
}

export default Page2;
