import './index.css';
import React, {useState} from "react";

function Topbar() {

    const [opened, setOpened] = useState(false);

    return (
        <div className="topbar-m white">
            <div className={"background" + (opened ? " opened" : "")} onClick={()=>setOpened( opened => !opened)}/>
            <div className={"icon" + (opened ? " opened" : "")} onClick={()=>setOpened( opened => !opened)}/>
            <div className={"menu" + (opened ? " opened" : "")}>
                <div onClick={()=> {
                    window.location.href="#m0";
                    document.getElementsByClassName("topbar-m")[0].classList.add("white")
                }}>
                    M. 0
                </div>
                <div onClick={()=> {
                    window.location.href="#about-us";
                    document.getElementsByClassName("topbar-m")[0].classList.add("white")
                }}>
                    About
                </div>
                <div onClick={()=> {
                    window.location.href="#what-we-do";
                    document.getElementsByClassName("topbar-m")[0].classList.remove("white")
                }}>
                    What we do
                </div>
                <div onClick={()=> {
                    window.location.href="#contact";
                    document.getElementsByClassName("topbar-m")[0].classList.add("white")
                }}>
                    Contact
                </div>
            </div>
        </div>
    );
}

export default Topbar;
