import Page1 from "./Page1";
import Page2 from "./Page2";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7_Project from "./Page7-Project";
import Page7_Rnd from "./Page7-Rnd";
import Topbar from "./TopbarMobile";
import Page8 from "./Page8";
import Page3 from "./Page3";

export default () => {
    return (
        <div>
            <div id="pagepiling">
                <div className="m0-section" id="m0">
                    <Page1/>
                </div>
                <div className="m0-section">
                    <Page2/>
                </div>
                <div className="m0-section">
                    <Page3/>
                </div>
                <div className="m0-section">
                    <Page4/>
                </div>
                <div className="m0-section" id="about-us">
                    <Page5/>
                </div>
                <div className="m0-section" id="what-we-do">
                    <Page6/>
                </div>
                <div className="m0-section">
                    <Page7_Project/>
                </div>
                <div className="m0-section">
                    <Page7_Rnd/>
                </div>
                <div className="m0-section" id="contact">
                    <Page8/>
                </div>
                <div id="popup-root" style="display:none">
                    <div data-testid="overlay" data-popup="modal" className="popup-overlay " tabIndex="-1"
                         style="position: fixed; inset: 0px; display: flex; z-index: 999; pointer-events: auto;">
                        <div className="popup-content " role="dialog" id="popup-1"
                             style="position: relative; margin: auto; pointer-events: auto;">
                            <div className="popup-close"></div>
                            <div className="popup-content-title">[신주발행공고]<br/></div>
                            <div className="popup-content-desc">
                                <br/>상법 제416조에 의거 2024년 9월 19일 개최된 당사 이사회에서 제3자 배정 유상증자에 따른 신주발행에 관하여 아래와 같이 결의하였기에 공고합니다.<br/><br/>
                            </div>
                            <div className="popup-content-desc center">-아래-<br/><br/></div>
                            <div className="popup-content-desc">
                                1. 신주식의 종류와 수 : 보통주식 4,927주<br/>
                                2. 신주식의 액면가액 : 1주 10,000원<br/>
                                3. 신주식의 발행가액 : 1주 202,970원<br/>
                                4. 납입기일 : 2024년 10월 11일<br/>
                                5. 주금을 납입할 금융기관 : 국민은행 강남타운점<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;단, 주식납입채무는 회사의 채권과 상계가 가능하다.<br/>
                                6. 신주식의 인수방법 : 당사 정관 제9조 제2항 제7호에 의거, 전부 제3자에게 배정함.<br/>
                                <br/>
                            </div>
                            <div className="popup-content-desc center">
                                2024년 9월 25일<br/>
                                서울 성동구 왕십리로115, 6층 지608호, 지609호(성수동1가,헤이그라운드서울숲점)<br/>
                                주식회사 랩엠제로 대표이사 신태호 (직인생략)<br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Topbar/>
        </div>
    )
}
