import './index.css';
import React from "react";

function Page1() {

    return (
        <div id="page1" className="page1 panel">
            <video className="background"
                autoPlay muted loop id="myVideo" width="100%" height="100%">
                <source src="bg.mp4" type="video/mp4"/>
            </video>
            <div className="wrapper">
                <div className={"content"}>
                    <div className={"letter-m"}/>
                    <div className={"letter-o"}/>
                </div>
            </div>
        </div>
    );
}

export default Page1;
