import './index.css';
import React from "react";

function Page2() {

    return (
        <div className="page2-m">
            <div className="wrapper">
            <div className="content">
                <div className="text">
                    엠.제로<span>(M.0)</span>는 소재와 디자인을 통해<br/>
                    미래를 제시합니다.
                </div>
                <div className="desc">
                    We shape the future with materials and design.
                </div>
            </div>
            </div>
        </div>
    );
}

export default Page2;
